$primary-background: #0085FF;
$secondary-background: #45A3FB;
$options-color: #3B9EFB;
$gold: #FBC53B;
$correct: #7DDE92; 
$grey: #D8D8D8;
$white: #FFFFFF;

//fonts

$light: 'Satoshi-Light';
$regular: 'Satoshi-Regular';
$medium: 'Satoshi-Medium';
$bold: 'Satoshi-Bold';
$black: 'Satoshi-Black';

$button-height: 2.8rem;