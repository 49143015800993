@import './../core/variables.scss';

.quiz-block {
    background-color: $secondary-background;
    max-width: 20%;
    height: fit-content;
    padding: 30px 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 4px 4px 20px 5px rgba($color: #000000, $alpha: 0.1);
    .progress-bar { 
        background-color: $grey;
        width: 100%;
        height: 5px;
        border-radius: 10px;
        .progress-bar-inner {
            background-color: $white;
            height: 100%;
            width: 100%;
            border-radius: inherit;
        }
    }
    .content {
        color: $white;
        .question-number, .question-category {
            font-family: $light;
        }
        .current-question {
            font-family: $medium;
            font-size: 15px;
        }
        h5 {
            margin: 0;
            margin-top: 10px;
            margin-bottom: 5px;
        }
    }
    .options {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        .option-button {
            text-align: left;
            padding: 5px 15px;
            min-height: $button-height;
            width: 80%;
            background-color: $white;
            border: none;
            border-radius: 10px;
            color: $options-color;
            font-family: $bold;
            cursor: pointer;

            &:active {
                transform: scale(0.95);
            }

            &.active {
                background-color: transparent;
                border: solid 2px $gold;
                color: $white;
            }

            &.correct {
                background-color: $correct;
                color: $white;
                border: none;
            }
            &.wrong {
                background-color: $grey;
                border: none;
            }
        }
    }
    .submit-button {
        text-align: center;
        background-color: $gold;
        border: none;
        border-radius: 10px;
        padding: 0px 10px;
        min-height: $button-height;
        width: 80%;
        align-self: center;
        color: $white;
        font-family: $bold;
        text-transform: uppercase;
        cursor: pointer;
        &:active {
            transform: scale(0.95);
        }
    }
}

@media screen and (max-width: 400px) {
    .quiz-block {
        max-width: 100%;
        height: 100%;
        gap: 35px;
        background-color: $primary-background;
        box-shadow: none;
        padding: 40px 30px;

        .options {
            .option-button {
                font-size: 1rem;
                width: 100%;
            }
        }
        .submit-button {
            font-size: 1rem;
            width: 100%;
        }
    }
}