@import './core/variables.scss';
.container {
    background-color: $primary-background;
    min-height: 100vh;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 400px) {
    .container {
        align-items: flex-start;
        padding: 0px;
    }
    
}