@import './../../core/variables.scss';

.loading-screen {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
        font-family: $medium;
        color: $white;
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 400px) {
    .loading-screen {
        align-items: center;
        padding: 0 10px;
    }    
}