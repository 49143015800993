@import '../../core/variables.scss';

.result-container {
    height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        font-family: $medium;
        text-align: center;
    }
    p {
        font-family: $regular;
        margin-top: 3px;
    }
}