.loading-screen {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.loading-screen h1 {
  color: #fff;
  font-family: Satoshi-Medium;
  font-size: 1.5rem;
}

@media screen and (max-width: 400px) {
  .loading-screen {
    align-items: center;
    padding: 0 10px;
  }
}

.quiz-block {
  max-width: 20%;
  height: fit-content;
  background-color: #45a3fb;
  border-radius: 20px;
  flex-direction: column;
  gap: 15px;
  padding: 30px;
  display: flex;
  box-shadow: 4px 4px 20px 5px #0000001a;
}

.quiz-block .progress-bar {
  width: 100%;
  height: 5px;
  background-color: #d8d8d8;
  border-radius: 10px;
}

.quiz-block .progress-bar .progress-bar-inner {
  height: 100%;
  width: 100%;
  border-radius: inherit;
  background-color: #fff;
}

.quiz-block .content {
  color: #fff;
}

.quiz-block .content .question-number, .quiz-block .content .question-category {
  font-family: Satoshi-Light;
}

.quiz-block .content .current-question {
  font-family: Satoshi-Medium;
  font-size: 15px;
}

.quiz-block .content h5 {
  margin: 10px 0 5px;
}

.quiz-block .options {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  display: flex;
}

.quiz-block .options .option-button {
  text-align: left;
  min-height: 2.8rem;
  width: 80%;
  color: #3b9efb;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  font-family: Satoshi-Bold;
}

.quiz-block .options .option-button:active {
  transform: scale(.95);
}

.quiz-block .options .option-button.active {
  color: #fff;
  background-color: #0000;
  border: 2px solid #fbc53b;
}

.quiz-block .options .option-button.correct {
  color: #fff;
  background-color: #7dde92;
  border: none;
}

.quiz-block .options .option-button.wrong {
  background-color: #d8d8d8;
  border: none;
}

.quiz-block .submit-button {
  text-align: center;
  min-height: 2.8rem;
  width: 80%;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #fbc53b;
  border: none;
  border-radius: 10px;
  align-self: center;
  padding: 0 10px;
  font-family: Satoshi-Bold;
}

.quiz-block .submit-button:active {
  transform: scale(.95);
}

@media screen and (max-width: 400px) {
  .quiz-block {
    max-width: 100%;
    height: 100%;
    box-shadow: none;
    background-color: #0085ff;
    gap: 35px;
    padding: 40px 30px;
  }

  .quiz-block .options .option-button, .quiz-block .submit-button {
    width: 100%;
    font-size: 1rem;
  }
}

.result-container {
  height: 100vh;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.result-container h1 {
  text-align: center;
  font-family: Satoshi-Medium;
}

.result-container p {
  margin-top: 3px;
  font-family: Satoshi-Regular;
}

.container {
  min-height: 100vh;
  width: 100%;
  background-color: #0085ff;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

@media screen and (max-width: 400px) {
  .container {
    align-items: flex-start;
    padding: 0;
  }
}

@font-face {
  font-family: Satoshi-Light;
  src: url("Satoshi-Light.7f02ab08.woff2") format("woff2"), url("Satoshi-Light.0a6976bf.woff") format("woff"), url("Satoshi-Light.db4cda7c.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Satoshi-Regular;
  src: url("Satoshi-Regular.5cd7338f.woff2") format("woff2"), url("Satoshi-Regular.23214b96.woff") format("woff"), url("Satoshi-Regular.e855a0d3.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Satoshi-Medium;
  src: url("Satoshi-Medium.5be63f72.woff2") format("woff2"), url("Satoshi-Medium.de33c0c3.woff") format("woff"), url("Satoshi-Medium.0cd9b7ce.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Satoshi-Bold;
  src: url("Satoshi-Bold.9042e65d.woff2") format("woff2"), url("Satoshi-Bold.d6123eb6.woff") format("woff"), url("Satoshi-Bold.642f978f.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Satoshi-Black;
  src: url("Satoshi-Black.ae0b9de3.woff2") format("woff2"), url("Satoshi-Black.fb3487f7.woff") format("woff"), url("Satoshi-Black.917ccca2.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

body {
  box-sizing: border-box;
  background-color: #0085ff;
  margin: 0;
  padding: 0;
}

body h1 {
  margin: 0;
  padding: 0;
}

/*# sourceMappingURL=index.6504e513.css.map */
