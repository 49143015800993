@import url('./core/satoshi.css');
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #0085FF;
}

body h1 {
    padding: 0;
    margin: 0;
}